/**
 * Decide how different locales specified in the next.config.js should map to in Contentful
 * For example en-GB in next.config.js could map to en-US. This depends on what you have setup in contentful as well.
 * @key = defined in next.config.js
 * @value = defined in contentful
 */
const ContentfulLocale = {
  'sv-SE': 'sv-SE',
  'en-US': 'en-US',
  'en-GB': 'en-US',
};

export default ContentfulLocale;
