import { FC, useEffect, useState } from 'react';
import Link from 'next/link';

// Components
import { Layout } from 'components/common';

// Services
import { getContentfulSettings } from 'services/contentful';
import { useRouter } from 'next/router';
import { CfSettings } from 'interfaces/Contentful';

const NotFoundPage: FC = () => {
  const router = useRouter();
  const [settings, setSettings] = useState<CfSettings | undefined>();
  useEffect(() => {
    const fetchContentfulSettings = async () => {
      const settings = await getContentfulSettings(router.locale);
      setSettings(settings.data);
    };
    fetchContentfulSettings();
  }, [router.locale]); 

  if (!settings) {
    return null;
  }

  return (
    <Layout pageProps={{ settings: settings }}>
      <div className='container max-width-sm min-height-100% padding-y-xxl'>
        <div className='text-component text-center'>
          <h1>Ops something went wrong.</h1>
          <p>Sorry, something went wrong on our end. A notice has been sent to us.</p>
          <p>
            <Link href='/' passHref>
              <a title='Go back to the homepage'>Go to homepage</a>
            </Link>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
