import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import https from 'https';

// Interfaces
import { CfSettings, CfHome, CfCategory, CfEditorialPage, CfArticlePage } from 'interfaces/Contentful';
import ContentfulLocales from 'config/contentful/locales';

type CfResponse<T> = Promise<AxiosResponse<T>>;

export const contentfulClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_STORM_API_URL + '/contentful',
  withCredentials: true,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }), // Required if running towards non approved HTTPS certificates
});

contentfulClient.interceptors.request.use(
  function (config) {
    if (config.params?.locale) {
      // @ts-ignore we check if locale is defined and override it with our custom mapping
      config.params.locale = ContentfulLocales[config?.params?.locale];
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Contentful endpoints
export const getContentfulSettings = (locale?: string): CfResponse<CfSettings> => {
  const config = { params: { locale } };
  return contentfulClient.get<CfSettings>('/settings', config);
};

export const getContentfulStartPage = (config: AxiosRequestConfig): CfResponse<CfHome> => {
  return contentfulClient.get<CfHome>(`/start`, config);
};

export const getContentfulCategoryPage = (slug: string, locale?: string): CfResponse<CfCategory> => {
  const config = { params: { locale } };
  return contentfulClient.get<CfCategory>(`/categories/${slug}`, config);
};

export const getContentfulCategoryId = (id: string, locale?: string): CfResponse<CfCategory> => {
  const config = { params: { locale } };
  return contentfulClient.get<CfCategory>(`/categories/id/${id}`, config);
};

export const getContentfulEditorialPage = (slug: string, locale?: string): CfResponse<CfEditorialPage> => {
  const config = { params: { locale } };
  return contentfulClient.get<CfEditorialPage>(`/editorials/${slug}`, config);
};

export const getContentfulEditorialPages = (locale?: string): CfResponse<CfEditorialPage[]> => {
  const config = { params: { locale } };
  return contentfulClient.get<CfEditorialPage[]>(`/editorials`, config);
};

export const getContentfulArticlePage = (slug: string, locale?: string): CfResponse<CfArticlePage> => {
  const config = { params: { locale } };
  return contentfulClient.get<CfArticlePage>(`/articles/${slug}`, config);
};

export const getContentfulArticlePages = (locale?: string): CfResponse<CfArticlePage[]> => {
  const config = { params: { locale } };
  return contentfulClient.get<CfArticlePage[]>(`/articles`, config);
};
